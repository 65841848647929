import React from 'react';
import "../Styles/main.css";


function AboutUs() {
    return (
      
        <div className='paragraf'> 
                <div className='about'>
                <p>&nbsp;</p>
            <div className='graph-header'>
            <p><strong></strong><strong>EKİBİMİZ VE HEDEFİMİZ</strong></p>
            </div>
                <p><strong>&nbsp;</strong></p>
                <p>Çevresel sürdürülebilirliğe duyduğumuz ortak tutkuyu paylaşan üç üniversite öğrencisiyiz. Plastik kirliliğinin gezegenimiz üzerindeki etkisi hakkında daha fazla bilgi edindikçe, çevremizin geleceği ve tüm canlıların refahı konusunda giderek daha fazla endişe duymaya başladık.</p>
                <p>&nbsp;</p>
                <p><strong>Çevresel farkındalık bu problemin çözümünde kritik bir rol oynamaktadır</strong>, bu nedenle harekete geçmeye ve insanların günlük tercihlerinin çevre üzerindeki etkisini anlamalarına yardımcı olabilecek bir araç oluşturmaya karar verdik. Plastik Ayak İzi Hesaplayıcımız, Türkiye'de <strong>türünün ilk örneği</strong>dir. Bireylerin yaşam tarzları ve tüketim alışkanlıkları hakkında verdikleri bilgilere dayanarak plastik ayak izlerini hesaplamalarına olanak tanıyan kullanıcı dostu bir çevrimiçi platformdur. <u>Yıllık ayak izinin tahmini sayısal bir değerini sağlayarak, insanları daha sürdürülebilir tercihler yapmaya teşvik etmeyi ve çevre üzerindeki etkilerini azaltmayı umuyoruz.</u></p>
                <p>&nbsp;</p>
                <p><strong>TÜBİTAK'tan destek almak bizim için büyük bir fırsattı</strong>,  fikrimizi gerçeğe dönüştürmemize yardımcı oldu. Küçük değişikliklerin büyük farklar yaratabileceğine inanıyoruz ve insanları bu değişiklikleri yapmaya teşvik etmek istiyoruz.</p>
                <p>&nbsp;</p>
                <p>Platformumuz, çevresel konularda bilgi düzeyinden bağımsız olarak <strong>kullanıcılar tarafından erişilebilir olmayı hedefler.</strong> Plastik Ayak İzi Hesaplayıcımızı kullanarak, insanların çevre üzerindeki etkileri hakkında daha bilinçli olmalarını ve bunu azaltmak için adımlar atmalarını umuyoruz. Birlikte, kendimiz ve gelecek nesiller için daha temiz, daha sağlıklı bir gezegen yaratabiliriz. Plastik Ayak İzi Hesaplayıcımızın sadece <strong>geleceğe doğru attığımız adımın başlangıcı olduğuna</strong> inanıyoruz. Çalışmalarımıza devam etme ve dünyaya olumlu bir etki yapma konusundaki taahhüdümüzü sürdürüyoruz.</p>
            </div>
      <div className='others-cont'>  
        <div className='about-cards'>
            
          {/* card 1 */}
          <figure class="snip1559">
            <div class="profile-image"><img src="Ezgiii.jpg" alt="profile-sample2" /></div>
            <figcaption>
              <h3>Ezgi Akar</h3>
              <h5>Yürütücü</h5>
              <p>                                                 </p>
              <div class="icons">
                <a href="#"><i class="ion-social-linkedin"></i></a>
                <a href="#"><i class="ion-social-twitter"></i></a>
              </div>
            </figcaption>
          </figure>
          {/* card 2 */}
          <figure class="snip1559">
            <div class="profile-image"><img src="moam2.png" alt="profile-sample7" /></div>
            <figcaption>
              <h3>Molham Hadri</h3>
              <h5>Sitenin kurucusu </h5>
              <p>                                      </p>
              <div class="icons">
                <a href="#"><i class="ion-social-linkedin"></i></a>
                <a href="#"><i class="ion-social-twitter"></i></a>
              </div>
            </figcaption>
          </figure>
          {/* card 3 */}
          <figure class="snip1559">
            <div class="profile-image"><img src="Zehraa.jpg" alt="profile-sample6" /></div>
            <figcaption>
              <h3>Fatma Zehra Aydin</h3>
              <h5>Araştırmacı</h5>
              <p>                                                         </p>
              <div class="icons">
                <a href="#"><i class="ion-social-linkedin"></i></a>
                <a href="#"><i class="ion-social-twitter"></i></a>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>  
    );
  }
  export default AboutUs;
  