import React, {useState, useEffect} from 'react';
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main.css";
import { NavLink } from 'react-router-dom';

function Navbar() {
	const navRef = useRef();
	const [isSticky, setSticky] = useState(false);
  
	useEffect(() => {
	  window.addEventListener('scroll', handleScroll);
	  return () => {
		window.removeEventListener('scroll', handleScroll);
	  };
	}, []);
  
	const handleScroll = () => {
	  if (window.pageYOffset > 0) {
		setSticky(true);
	  } else {
		setSticky(false);
	  }
	};
	const showNavbar = () => {
	  navRef.current.classList.toggle('responsive_nav');
	};
	return (
		<header className={`navbar ${isSticky ? 'sticky' : ''}`}>
		  <div className="image">
			<img alt="" src="imageedit_8_9186497135.png" className="header-logo" />
			<h3>Plastik Ayak İzi Hesaplama Aracı</h3>
		  </div>
		  <nav ref={navRef} style={{marginLeft:'10px'}}>
			<NavLink exact to="/">
			  Anasayfa
			</NavLink>
			<NavLink to="/AboutUs">Hakkımızda</NavLink>
			<NavLink to="/ContactUs">Bizimle İletişime Geçin</NavLink>
			<button className="nav-btn nav-close-btn" onClick={showNavbar}>
			  <FaTimes />
			</button>
		  </nav>
		  <button className="nav-btn" onClick={showNavbar}>
			<FaBars />
		  </button>
		</header>
	);
  }
export default Navbar;