import React, { useState } from 'react';

function FoodKitchenForm() {
  const [bottles, setBottles] = useState(0);
  const [plasticBags, setPlasticBags] = useState(0);
  const [wrappers, setWrappers] = useState(0);
  const [yogurtContainers, setYogurtContainers] = useState(0);
  const [ShampooBottles, setShampooBottles] = useState(0);
  const [Toothbrushes, setToothbrushers] = useState(0);
  const [Toothpastes, setToothpastes] = useState(0);
  const [CottonSwabs, setCottonSwabs] = useState(0);

  const [PlasticPackage, setPlasticPackage] = useState(0);
  const [PlasticCup, setPlasticCup] = useState(0);
  const [PlasticStraw, setPlasticStraw] = useState(0);
  const [PlasticPlate, setPlasticPlate] = useState(0);
  const [PlasticKnifeFork, setPlasticKnifeFork] = useState(0);  

  const [bottlesMultiplier, setBottlesMultiplier] = useState(52);
  const [plasticBagsMultiplier, setPlasticBagsMultiplier] = useState(52);
  const [wrappersMultiplier, setWrappersMultiplier] = useState(52);
  const [yogurtContainersMultiplier, setYogurtContainersMultiplier] = useState(12);
  const [ToothbrushesMultiplier, setToothbrushersMultiplier] = useState(1);
  const [ToothpastesMultiplier, setToothpastesMultiplier] = useState(1);
  const [CottonSwabsMultiplier, setCottonSwabsMultiplier] = useState(52);
  const [ShampooBottlesMultiplier, setShampooBottlesMultiplier] = useState(2);

  const [PlasticPackageMultiplier, setPlasticPackageMultiplier] = useState(52);
  const [PlasticCupMultiplier, setPlasticCupMultiplier] = useState(52);
  const [PlasticStrawMultiplier, setPlasticStrawMultiplier] = useState(52);
  const [PlasticPlateMultiplier, setPlasticPlateMultiplier] = useState(52);
  const [PlasticKnifeForkMultiplier, setPlasticKnifeForkMultiplier] = useState(52);


//  const [bottlesCO2Multiplier, setBottlesCO2Multiplier] = useState(52);
  //const [plasticBagsCO2Multiplier, setPlasticCO2BagsMultiplier] = useState(1);
  //const [wrappersCO2Multiplier, setWrappersCO2Multiplier] = useState(1);
  //const [yogurtContainersCO2Multiplier, setYogurtContainersCO2Multiplier] = useState(1);
  //const [ToothbrushesCO2Multiplier, setToothbrushersCO2Multiplier] = useState(1);
  //const [ToothpastesCO2Multiplier, setToothpastesCO2Multiplier] = useState(1);
  //const [CottonSwabsCO2Multiplier, setCottonSwabsCO2Multiplier] = useState(1);
  //const [ShampooBottlesCO2Multiplier, setShampooBottlesCO2Multiplier] = useState(1);  


// Each element an its corresponding multiplier will be prosesed here and the sum of all of them will be displayed
  const resultBottles = bottles * 0.0375 * bottlesMultiplier;
  const resultPlasticBags = plasticBags * 0.0075 * plasticBagsMultiplier;
  const resultWrappers = wrappers * 0.02417 * wrappersMultiplier;
  const resultYogurtContainers = yogurtContainers * 0.02417 * yogurtContainersMultiplier;
  const resultShampooBottles = ShampooBottles * 0.09 * ShampooBottlesMultiplier;
  const resultCottonSwabs = CottonSwabs * 0.001 * CottonSwabsMultiplier;
  const resultToothpaste = Toothpastes * 0.015 * ToothpastesMultiplier;
  const resultToothbrush = Toothbrushes * 0.02 * ToothbrushesMultiplier;
  const resultPlasticPackage = PlasticPackage * 0.0241667 * PlasticPackageMultiplier;
  const resultPlasticCup = PlasticCup * 0.0195 * PlasticCupMultiplier;
  const resultPlasticStraw = PlasticStraw * 0.00075 * PlasticStrawMultiplier;
  const resultPlasticPlate = PlasticPlate * 0.024 * PlasticPlateMultiplier;
  const resultPlasticKnifeFork = PlasticKnifeFork * 0.045 * PlasticKnifeForkMultiplier;

  const totalResult = resultBottles + resultPlasticBags +
   resultWrappers + resultYogurtContainers +resultShampooBottles +
   resultCottonSwabs + resultToothpaste + resultToothbrush + resultPlasticPackage
   +resultPlasticCup + resultPlasticStraw + resultPlasticPlate + resultPlasticKnifeFork;

  // Handle CO2 multipliers
  const resultBottlesCO2 = bottles * 0.062055 * bottlesMultiplier;
  const resultPlasticBagsCO2 = plasticBags * 0.006499 * plasticBagsMultiplier;
  const resultWrappersCO2 = wrappers * 0.007990 * wrappersMultiplier;
  const resultYogurtContainersCO2 = yogurtContainers * 0.047365 * yogurtContainersMultiplier;
  const resultShampooBottlesCO2 = ShampooBottles * 0.091323 * ShampooBottlesMultiplier;
  const resultCottonSwabsCO2 = CottonSwabs * 0.001082 * CottonSwabsMultiplier;
  const resultToothpasteCO2 = Toothpastes * 0.086938 * ToothpastesMultiplier;
  const resultToothbrushCO2 = Toothbrushes * 0.095784 * ToothbrushesMultiplier;

  // CO2 footprint calculation
  const totalResultCO2 = resultBottlesCO2 + resultPlasticBagsCO2 + resultWrappersCO2 + resultYogurtContainersCO2 
  + resultShampooBottlesCO2 + resultCottonSwabsCO2 + resultToothpasteCO2 + resultToothbrushCO2;

  // Here we handle the Kitchens's plastic waste's count change
  const handleBottlesChange = (event) => {
    setBottles(Number(event.target.value));
  };

  const handlePlasticBagsChange = (event) => {
    setPlasticBags(Number(event.target.value));
  };

  const handleWrappersChange = (event) => {
    setWrappers(Number(event.target.value));
  };

  const handleYogurtContainersChange = (event) => {
    setYogurtContainers(Number(event.target.value));
  };


// Here we handle the Bathrooms's plastic waste's count change
  const handleToothbrushesChange = (event) => {
        setToothbrushers(Number(event.target.value));
    };
  const handleToothpastesChange = (event) => {
        setToothpastes(Number(event.target.value));
    };
  const handleCottonSwabsChange = (event) => {
    setCottonSwabs(Number(event.target.value));
};
  const handleShampooBottlesChange = (event) => {
    setShampooBottles(Number(event.target.value));
  };

  const handlePlasticPackageChange = (event) => {
    setPlasticPackage(Number(event.target.value));
  };
  const handlePlasticCupChange = (event) => {
      setPlasticCup(Number(event.target.value));
  };
  const handlePlasticStrawChange = (event) => {
  setPlasticStraw(Number(event.target.value));
  };
  const handlePlasticPlateChange = (event) => {
  setPlasticPlate(Number(event.target.value));
  };
  const handlePlasticKnifeForkChange = (event) => {
    setPlasticKnifeFork(Number(event.target.value));
  };

// Here we handle the Kitchen's plastic waste's multiplier change
  const handleBottlesMultiplierChange = (event) => {
    setBottlesMultiplier(Number(event.target.value));
  };

  const handlePlasticBagsMultiplierChange = (event) => {
    setPlasticBagsMultiplier(Number(event.target.value));
  };

  const handleWrappersMultiplierChange = (event) => {
    setWrappersMultiplier(Number(event.target.value));
  };

  const handleYogurtContainersMultiplierChange = (event) => {
    setYogurtContainersMultiplier(Number(event.target.value));
  };


// Here we handle the Bathrooms plastic waste's multiplier change
  const handleToothbrushesMultiplierChange = (event) => {
    setToothbrushersMultiplier(Number(event.target.value));
};
  const handleToothpastesMultiplierChange = (event) => {
    setToothpastesMultiplier(Number(event.target.value));
};
  const handleCottonSwabsMultiplierChange = (event) => {
    setCottonSwabsMultiplier(Number(event.target.value));
};
  const handleShampooBottlesMultiplierChange = (event) => { 
    setShampooBottlesMultiplier(Number(event.target.value));
};


const handlePlasticPackageMultiplierChange = (event) => {
  setPlasticPackageMultiplier(Number(event.target.value));
};
const handlePlasticCupMultiplierChange = (event) => {
  setPlasticCupMultiplier(Number(event.target.value));
};
const handlePlasticStrawMultiplierChange = (event) => {
  setPlasticStrawMultiplier(Number(event.target.value));
};
const handlePlasticPlateMultiplierChange = (event) => { 
  setPlasticPlateMultiplier(Number(event.target.value));
};
const handlePlasticKnifeForkMultiplierChange = (event) => { 
  setPlasticKnifeForkMultiplier(Number(event.target.value));
};


  return (
    <div class="promo">
    <div className="container">
        <div className="flex content">
            <div className="flex leftandcenter">
                <div className="flex display">
                    <div className="center-content"> 
              
                    <h1>Plastik Ayak İzi Hesaplama Aracı</h1>
            <div>
                <div> Bu web sitesi TÜBİTAK 2209-A-2023 çerçevesinde "Yeni Bir Ayak İzi: Üniversite Öğrencilerinde Plastik Atık Ayak İzi Farkındalığı" adlı proje kapsamında desteklenmekte olup, ilgili projenin araştırmacısı <strong><u>Molham HADRI </u></strong>tarafından tasarlanmıştır.</div>
                <div>
                    <div>
                        <hr></hr>
                    </div>
                </div>
            </div>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Plastikler, d&uuml;nya &ccedil;apında kullanılan en yaygın malzemelerden biridir. Plastik &uuml;retimi 1950&apos;de 2 milyon tondan (Mt) 2020&apos;de 367 Mt&apos;a y&uuml;kseldi ve y&uuml;kselmeye de devam ediyor. Buna g&ouml;re plastik &uuml;retiminin <strong>2050 yılına kadar 2600 Mt&apos;a</strong> ulaşacağı tahmin ediliyor. Plastiklerle ilgili &ccedil;arpıcı bilgiler sunan videoya <u><a href="https://www.youtube.com/watch?v=OF5ea-q28o4" style={{color: 'rgb(5, 99, 193)'}}>bağlantıya tıklayarak ulaşabilirsiniz.</a></u></p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Plastik &uuml;r&uuml;nlerin t&uuml;ketiminden sonra ise plastiklerin geri d&ouml;n&uuml;ş&uuml;m&uuml; sanıldığının aksine kolay olmuyor bununla birlikte bertarafıyla birlikte zararlı partik&uuml;l maddelerin havaya salınması sonucunda halk ve &ccedil;evre sağlığı i&ccedil;in risk oluşturuyor!</p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Bu konuda peki <strong>bizler</strong> ne <strong>yapabiliriz</strong>? Bu sadece b&uuml;y&uuml;k şirketler ve end&uuml;striler i&ccedil;in mi bir sorun?</p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Tabi ki &nbsp;<strong>HAYIR!</strong></p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Bu konuda bilgilenmek, bireysel farkındalığı arttırmak, yaşam tarzını değiştirmek ve harekete ge&ccedil;ebilmek olduk&ccedil;a &ouml;nem taşıyor.</p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Tam da bu konuda plastik kirliliğine dikkat &ccedil;ekmek i&ccedil;in farkındalık &ccedil;alışmaları yapılmakta olup <strong>&ldquo;Kumlu plajlarda ayak izi bırakan sadece ayaklarımız değil.&rdquo;</strong> Sloganı ile plastik ayak izi, plastik malzemelere olan bağımlılığın &ccedil;evrede g&ouml;r&uuml;n&uuml;r etkisini sayısallaştırmaktadır.</p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Bu hesaplama aracı ile plastik kullanımını g&ouml;zler &ouml;n&uuml;ne sermek ve plastik kirliliğine farkındalık oluşturmak istenmiştir.</p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Plastikler ve tehlikeleri ile ilgili daha fazla bilgi edinmek isterseniz yazımızın devamında buluşalım&hellip;</p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '25px' }}><strong>Plastiklerin Doğadaki S&uuml;reci</strong></p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize: '15px' }}>Plastik &uuml;r&uuml;nlerin t&uuml;ketiminden sonra plastiklerin bir kısmı geri d&ouml;n&uuml;şt&uuml;r&uuml;lerek plastik yaşam d&ouml;ng&uuml;s&uuml;n&uuml;n plastik &uuml;retim aşamasına geri d&ouml;ner. &Uuml;retilen plastiklerin y&uuml;zde 79&rsquo;u d&uuml;zenli ve vahşi depolamada y&uuml;zde 12&apos;si yakılarak bertaraf edilir ve sadece <strong>y&uuml;zde 9&apos;u geri d&ouml;n&uuml;şt&uuml;r&uuml;l&uuml;r</strong>.</p>
            <p style={{ textAlign: 'start',color: 'rgb(0, 0, 0)',fontSize:'15px' }}>Plastikler d&uuml;zenli depolanmadığında doğal mikroplastiklere d&ouml;n&uuml;şerek toprağı, suyu, havayı ve canlı yaşamını olumsuz etkiliyor. Solunması veya maruz kalınmasıyla birlikte kanser, astım, kısırlık ve ruhsal bozukluk riskini arttırabileceği d&uuml;ş&uuml;n&uuml;l&uuml;yor!</p>
            <p style={{ textAlign: 'start',color: 'rgb(0, 0, 0)',fontSize:'15px' }}>Y&ouml;netilemeyen plastikler alıcı ortamlarla buluştuğunda canlı organizmalarda birikerek tabağımızda yer bulur!</p>
            
            <p style={{ textAlign: 'start',color: 'rgb(0, 0, 0)',fontSize:'15px' }}> Bu plastiklerin sucul ortamlarda endişe uyandıran s&uuml;recini izlemek i&ccedil;in <u><a href="https://www.youtube.com/watch?v=eoWiHRkZz24" style={{color: 'rgb(5, 99, 193)'}}> bağlantıya tıklayabilirsiniz.</a></u> izledikten sonra d&uuml;nyamız i&ccedil;in harekete ge&ccedil;mek isteyeceğinizden eminiz&hellip;</p> 
            
            <p><strong><span style={{fontSize: '25px'}}>Peki, plastik atıkların &ccedil;&ouml;z&uuml;nmesi ne kadar s&uuml;rer?</span></strong></p>
            <p style={{ textAlign: 'start',color: 'rgb(0, 0, 0)',fontSize:'15px' }}>Bilim insanları plastiklerin 500 yıldan daha uzun s&uuml;rede par&ccedil;alandığını tahmin ediyor, ancak belirli &ouml;ğeler i&ccedil;in bu s&uuml;re daha kısadır:</p>
            <table style={{ borderCollapse: 'collapse', border: 'none' }}>
            <tbody>
        <tr>
            <td style={{ width: '246.55pt', borderWidth: '2pt 2pt 2pt', borderStyle: 'solid', borderColor: 'rgb(197, 224, 179) rgb(197, 224, 179) rgb(168, 208, 141)', borderImage: 'initial', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
                <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', textAlign: 'center', lineHeight: 'normal' }}><strong>Kategori</strong></p>
            </td>
            <td style={{ width: '108pt', borderTop: '2pt solid rgb(197, 224, 179)', borderLeft: 'none', borderBottom: '2pt solid rgb(168, 208, 141)', borderRight: '2pt solid rgb(197, 224, 179)', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
                <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', textAlign: 'center', lineHeight: 'normal' }}><strong>S&uuml;re&ccedil;</strong></p>
            </td>
        </tr>
        <tr>
            <td style={{ width: '226.55pt', borderRight: '2pt solid rgb(197, 224, 179)', borderBottom: '2pt solid rgb(197, 224, 179)', borderLeft: '2pt solid rgb(197, 224, 179)', borderImage: 'initial', borderTop: 'none', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
                <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>Plastik poşetler</p>
            </td>
            <td style={{ width: '78pt', borderTop: 'none', borderLeft: 'none', borderBottom: '2pt solid rgb(197, 224, 179)', borderRight: '2pt solid rgb(197, 224, 179)', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>10-1000 yıl</p>
    </td>
        </tr>
<tr>
    <td style={{ width: '226.55pt', borderRight: '2pt solid rgb(197, 224, 179)', borderBottom: '2pt solid rgb(197, 224, 179)', borderLeft: '2pt solid rgb(197, 224, 179)', borderImage: 'initial', borderTop: 'none', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>Alışveriş poşetleri</p>
    </td>
    <td style={{ width: '78pt', borderTop: 'none', borderLeft: 'none', borderBottom: '2pt solid rgb(197, 224, 179)', borderRight: '2pt solid rgb(197, 224, 179)', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>10-20 yıl</p>
    </td>
</tr>
<tr>
    <td style={{ width: '226.55pt', borderRight: '2pt solid rgb(197, 224, 179)', borderBottom: '2pt solid rgb(197, 224, 179)', borderLeft: '2pt solid rgb(197, 224, 179)', borderImage: 'initial', borderTop: 'none', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>K&ouml;p&uuml;k plastik bardaklar</p>
    </td>
    <td style={{ width: '78pt', borderTop: 'none', borderLeft: 'none', borderBottom: '2pt solid rgb(197, 224, 179)', borderRight: '2pt solid rgb(197, 224, 179)', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>50 yıl</p>
    </td>
</tr>
<tr>
    <td style={{ width: '226.55pt', borderRight: '2pt solid rgb(197, 224, 179)', borderBottom: '2pt solid rgb(197, 224, 179)', borderLeft: '2pt solid rgb(197, 224, 179)', borderImage: 'initial', borderTop: 'none', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>Tek kullanımlık plastik bardaklar ve tabaklar</p>
    </td>
    <td style={{ width: '78pt', borderTop: 'none', borderLeft: 'none', borderBottom: '2pt solid rgb(197, 224, 179)', borderRight: '2pt solid rgb(197, 224, 179)', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>400 yıl</p>
    </td>
</tr>
<tr>
    <td style={{ width: '226.55pt', borderRight: '2pt solid rgb(197, 224, 179)', borderBottom: '2pt solid rgb(197, 224, 179)', borderLeft: '2pt solid rgb(197, 224, 179)', borderImage: 'initial', borderTop: 'none', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>Tek kullanımlık bebek bezleri</p>
    </td>
    <td style={{ width: '78pt', borderTop: 'none', borderLeft: 'none', borderBottom: '2pt solid rgb(197, 224, 179)', borderRight: '2pt solid rgb(197, 224, 179)', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>450 yıl</p>
    </td>
</tr>
<tr>
    <td style={{ width: '226.55pt', borderRight: '2pt solid rgb(197, 224, 179)', borderBottom: '2pt solid rgb(197, 224, 179)', borderLeft: '2pt solid rgb(197, 224, 179)', borderImage: 'initial', borderTop: 'none', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>Plastik şişeler</p>
    </td>
    <td style={{ width: '78pt', borderTop: 'none', borderLeft: 'none', borderBottom: '2pt solid rgb(197, 224, 179)', borderRight: '2pt solid rgb(197, 224, 179)', padding: '0cm 5.4pt', verticalAlign: 'top' }}>
        <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri", sans-serif', lineHeight: 'normal' }}>450-500 yıl</p>
    </td>
</tr>
    </tbody>
            </table>
<p></p>
            <p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'15px' }}><strong><span style={{fontSize:'25px'}}>Plastiklerle M&uuml;cadelede 4R Kuralı:</span></strong></p>
<p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'20px' }}><strong>4 R&apos;ler kuralı: Reddet, Azalt, Yeniden Kullan, Geri D&ouml;n&uuml;şt&uuml;r</strong></p>
<p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'15px' }}>4 R&rsquo;ler kuralı atık y&ouml;netimi hiyerarşinden de bildiğimiz ger&ccedil;ekten d&uuml;nyamızı &ouml;nemseyenler i&ccedil;in temel bir konudur.</p>
<p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'15px' }}><strong>&Ouml;nle:</strong> Tek kullanımlık plastikleri hayatından &ccedil;ıkar! &Ouml;rneğin pipetler, plastik poşetler, tek kullanımlık &ccedil;atal-bı&ccedil;ak-bardak ve karıştırıcılar. Kısa s&uuml;rede t&uuml;ketilen bu plastikler y&uuml;zlerce yıl sonra da var olmaya devam edecekler&nbsp;☹&nbsp;:,(</p>
<p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'15px' }}><strong>Azalt:</strong> M&uuml;mk&uuml;n olan en az plastik atık &uuml;r&uuml;nleri se&ccedil;in. Plastik yerine kağıtla sarılmış &uuml;r&uuml;nleri tercih edin ve plastik olanlara g&ouml;re biyolojik olarak par&ccedil;alanabilen pamuklu kulak temizleme &ccedil;ubuklarını kullanın ve sentetik kıyafetler gibi plastik mikrofiberlerden ka&ccedil;ının.</p>
<p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'15px' }}>&nbsp;</p>
<p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'15px' }}><strong>Yeniden Kullan:</strong> Plastik kapları m&uuml;mk&uuml;n olduğunca &ccedil;ok kez kullanın. &Ouml;ğle yemeğinizi yeniden kullanılabilir kaplarda g&ouml;t&uuml;r&uuml;n.</p>
<p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'15px' }}>&nbsp;</p>
<p style={{ textAlign: 'start', color: 'rgb(0, 0, 0)', fontSize:'15px' }}><strong>Geri D&ouml;n&uuml;şt&uuml;r:</strong> Yukarıdaki se&ccedil;enekler işe yaramazsa, son se&ccedil;eneğe sarılın. Plastik (ve diğer malzemelerin) geri d&ouml;n&uuml;ş&uuml;m&uuml; g&uuml;nl&uuml;k alışkanlığımız olmalıdır. Geri d&ouml;n&uuml;şt&uuml;r&uuml;lm&uuml;ş malzemelerden yapılmış &uuml;r&uuml;nleri satın alın, geri d&ouml;n&uuml;ş&uuml;m&uuml; kolay olanları tercih edin ve geri d&ouml;n&uuml;ş&uuml;m kutusuna atmadan &ouml;nce atıklarınızı durulayın, b&ouml;ylece geri d&ouml;n&uuml;ş&uuml;m&uuml; imk&acirc;nsız hale getiren atık kirliliğinden ka&ccedil;ının. 😊 :)</p>

            </div>  
                <div className="left-part">
                    <div className="clac-container">
                        <div className="inner">
                            <div className='wrapper'>
                                <div className='wrapper top'>
                                <div className='wrapper form'>
                                <div className='calc-column'>
      <div className='column'>
      <div className="form-title">
        <span>Yemek &amp; Mutfak İhtiyaçları</span>
      </div>
      <div className="rowgroup">
        <label htmlFor="bottles" className='label1'>Şişeler</label>
        <input type="number" id="bottles" name="bottles" min="0" step="1" value={bottles} onChange={handleBottlesChange} />
        <select value={bottlesMultiplier} onChange={handleBottlesMultiplierChange}>
          <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      
      <div className="rowgroup">
        <label htmlFor="plastic_bags">Plastik Poşetler</label>
        <input type="number" id="plastic_bags" name="plastic_bags" min="0" step="1" value={plasticBags} onChange={handlePlasticBagsChange} />
        <select value={plasticBagsMultiplier} onChange={handlePlasticBagsMultiplierChange}>
          <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      <div className="rowgroup">
        <label htmlFor="wrappers">Gıda Ambalajı </label>
        <input type="number" id="wrappers" name="wrappers" min="0" step="1" value={wrappers} onChange={handleWrappersChange} />
        <select value={wrappersMultiplier} onChange={handleWrappersMultiplierChange}>
          <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      <div className="rowgroup">
        <label htmlFor="yogurt_containers">Yoğurt Kapları</label>
        <input type="number" id="yogurt_containers" name="yogurt_containers" min="0" step="1" value={yogurtContainers} onChange={handleYogurtContainersChange} />
            <select value={yogurtContainersMultiplier} onChange={handleYogurtContainersMultiplierChange}>
            <option value={52}>/ 1 hafta</option>
            <option value={12}>/ 1 ay</option>
            <option value={2}>/ 6 ay</option>
            <option value={1}>/ 1 yıl</option>  
            </select>
            <button className='rowgroup button'></button>
        </div>
      </div>

    <div className='column'>
      <div className="form-title">
        <span>Lavabo İhtiyaçları </span>
      </div>
      <div className="rowgroup">
        <label htmlFor="Toothbrushes">Diş Fırçaları</label>
        <input type="number" id="Toothbrushes" name="Toothbrushes" min="0" step="1" value={Toothbrushes} onChange={handleToothbrushesChange} />
        <select value={ToothbrushesMultiplier} onChange={handleToothbrushesMultiplierChange}>
          <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      
      <div className="rowgroup">
        <label htmlFor="Toothpastes">Diş Macunları</label>
        <input type="number" id="Toothpastes" name="Toothpastes" min="0" step="1" value={Toothpastes} onChange={handleToothpastesChange} />
        <select value={ToothpastesMultiplier} onChange={handleToothpastesMultiplierChange}>
        <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      <div className="rowgroup">
        <label htmlFor="Shampoo_Bottles">Şampuan Şişeleri</label>
        <input type="number" id="Shampoo_Bottles" name="Shampoo_Bottles" min="0" step="1" value={ShampooBottles} onChange={handleShampooBottlesChange} />
        <select value={ShampooBottlesMultiplier} onChange={handleShampooBottlesMultiplierChange}>
        <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      <div className="rowgroup">
        <label htmlFor="Cotton_Swabs">Kulak Çubukları </label>
        <input type="number" id="Cotton_Swabs" name="Cotton_Swabs" min="0" step="1" value={CottonSwabs} onChange={handleCottonSwabsChange} />
        <select value={CottonSwabsMultiplier} onChange={handleCottonSwabsMultiplierChange}>
        <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
    </div>

    <div className='column'>
      <div className="form-title">
        <span>Tek Kullanımlık Kaplar ve Ambalajlar</span>
      </div>
      <div className="rowgroup">
        <label htmlFor="Plastic_Package">Plastik Kutu</label>
        <input type="number" id="Plastic_Package" name="Plastic_Package" min="0" step="1" value={PlasticPackage} onChange={handlePlasticPackageChange} />
        <select value={PlasticPackageMultiplier} onChange={handlePlasticPackageMultiplierChange}>
          <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      
      <div className="rowgroup">
        <label htmlFor="Plastic_Cup">Plastik Bardak</label>
        <input type="number" id="Plastic_Cup" name="Plastic_Cup" min="0" step="1" value={PlasticCup} onChange={handlePlasticCupChange} />
        <select value={PlasticCupMultiplier} onChange={handlePlasticCupMultiplierChange}>
        <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      <div className="rowgroup">
        <label htmlFor="Plastic_Straw">Pipet</label>
        <input type="number" id="Plastic_Straw" name="Plastic_Straw" min="0" step="1" value={PlasticStraw} onChange={handlePlasticStrawChange} />
        <select value={PlasticStrawMultiplier} onChange={handlePlasticStrawMultiplierChange}>
        <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      <div className="rowgroup">
        <label htmlFor="Plastic_Plate">Plastik Tabak </label>
        <input type="number" id="Plastic_Plate" name="Plastic_Plate" min="0" step="1" value={PlasticPlate} onChange={handlePlasticPlateChange} />
        <select value={PlasticPlateMultiplier} onChange={handlePlasticPlateMultiplierChange}>
        <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
      <div className="rowgroup">
        <label htmlFor="Knife_Fork">Çatal Bıçak</label>
        <input type="number" id="Knife_Fork" name="Knife_Fork" min="0" step="1" value={PlasticKnifeFork} onChange={handlePlasticKnifeForkChange} />
        <select value={PlasticKnifeForkMultiplier} onChange={handlePlasticKnifeForkMultiplierChange}>
        <option value={52}>/ 1 hafta</option>
          <option value={12}>/ 1 ay</option>
          <option value={2}>/ 6 ay</option>
          <option value={1}>/ 1 yıl</option>  
        </select>
        <button className='rowgroup button'></button>
      </div>
    </div>
    <div className='column'>
    <div className="form-title">
        <span>Sonuç</span>
      </div>
      {/* <div className="rowgroup result">
        <h className="result-title"> Şişelerden Kaynaklanan Ayak İzi: {resultBottles.toFixed(2)} kg/yıl</h> <button className='rowgroup button result'></button>
      </div>
      <div className="rowgroup result">
      <h className="result-title">Plastik Poşetlerden Kaynaklanan Ayak İzi: {resultPlasticBags.toFixed(2)} kg/yıl</h> <button className='rowgroup button result'></button>
      </div>
      <div className="rowgroup result">
      <h className="result-title">Yoğurt Kaplarından Kaynaklanan Ayak İzi: {resultYogurtContainers.toFixed(2)} kg/yıl</h><button className='rowgroup button result'></button>
      </div> */}
      <div className="rowgroup result">
      <h className="result-title">Toplam Plastik Ayak İzi: {totalResult.toFixed(2)} kg/yıl</h> <button className='rowgroup button result'></button>
      </div>
      {/* <div className="rowgroup result">
      <h className="result-title">Toplam CO2 Ayak İzi Eşdeğeri: {totalResultCO2.toFixed(2)} kg/yıl</h> <button className='rowgroup button result'></button>
      </div> */}

    </div>   
                                               </div>{/* calc-column */} 
                                        </div> {/* wrapper form */}  
                                    </div> {/* wrapper top */}
                                </div> {/* wrapper */}
                             </div> {/* inner */}
                        </div> {/* clac-container */}
                    </div> {/* left-part */}
                </div>  {/* flex display */}
            </div> {/* flex leftandcenter */}
        </div> {/* content */}
    </div> {/* container */}
 </div> //promo
  );
}
export default FoodKitchenForm;
