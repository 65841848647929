import "./Styles/main.css";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from "./Components/Navbar";
import Sideform from "./Components/Sideform";
import AboutUs from "./Components/AboutUs";
import ContactUs from "./Components/ContactUs";

function App() {
	
	return (
			<Router>
		<React.Fragment>
			<Navbar/>
			<Routes>
			<Route exact path="/" element={<Sideform></Sideform>}>
			</Route>
			<Route path="/AboutUs" element={<AboutUs />}/>
			<Route path="/ContactUs" element={<ContactUs></ContactUs>}/>
		  </Routes>
		</React.Fragment>
	  </Router>
	);
}
export default App;