import React from 'react';
import "../Styles/main.css";

function ContactUs() {
    return (
        <div className='paragraf'> 
        <div className='about contact'>
        <p>&nbsp;</p>
    <div className='graph-header'>
    <p><strong></strong><strong>BİZİMLE İLETİŞİME GEÇİN</strong></p>
    <br></br>
        </div>
            <p>Sizden haber almayı çok isteriz! Herhangi bir sorunuz, öneriniz varsa veya yalnızca ekibimizle bağlantı kurmak istiyorsanız lütfen bizimle iletişime geçmekten çekinmeyin. Geri bildiriminiz ve katılımınız, plastik kirliliğine ilişkin farkındalığı artırmaya yönelik çabalarımızı geliştirmemize ve genişletmemize yardımcı olmak açısından çok değerlidir. </p>
            <p>Aşağıdaki e-posta yoluyla bizimle iletişime geçebilirsiniz:</p>
            <br></br>
            <li>E-mail: molhamhadri1@gmail.com</li>
            <li>E-mail: f.zydnn@gmail.com</li>
            <li>E-mail: ezgiakar1521@gmail.com</li>
            <br></br>
            <p>Mesajlarınızı sabırsızlıkla bekliyor ve sürdürülebilir bir gelecek yaratma tutkumuzu paylaşan benzer düşüncelere sahip bireyler, kuruluşlar ve topluluklarla işbirliği yapmayı sabırsızlıkla bekliyoruz. </p>
        </div>
    </div>
    );
}
export default ContactUs;